@use "sass:math";
// Import Literata font
@import url('https://fonts.googleapis.com/css2?family=Literata:ital,opsz,wght@0,7..72,200..900;1,7..72,200..900&display=swap');

// Base variables following Gutenberg's recommendations
$base: 16 * math.div(100, 100); // 16px for mobile
$desktop-base: 16 * math.div(112.5, 100); // 18px for desktop
$line-height: 1.625;
$line-height-desktop: 1.7;
$max-width: 40rem;

// First, let's set the root font size
:root {
  font-size: 100%; // This ensures 1rem = 16px
}

.gutenberg-typography {
  font-family: "Literata", Georgia, serif;
  font-weight: 500;
  font-size: 1rem; // 16px base
  line-height: $line-height;
  color: #222222;
  max-width: $max-width;
  margin: 0 auto;
  padding: 2rem;

  // Headings - using Gutenberg's recommended ratios
  h1, h2, h3, h4, h5, h6 {
    font-family: "Literata", Georgia, serif;
    font-weight: 700;
    margin-top: 2.75rem;
    margin-bottom: 1rem;
    line-height: 1.15;
  }

  h1 {
    font-size: 2.25rem; // 36px
    margin-top: 4rem;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.6875rem; // 27px
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.375rem; // 22px
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }

  // Paragraphs
  p {
    margin-bottom: 1.25rem;
    font-weight: 500;
  }

  // Links
  a {
    color: #0072C6;
    text-decoration: underline;
    
    &:hover {
      color: #005ea2;
    }
  }

  // Blockquotes
  blockquote {
    font-style: italic;
    font-weight: 400;
    margin: 2rem 0;
    padding-left: 1.5rem;
    border-left: 3px solid #888;
    font-size: 0.9375rem; // Slightly smaller than body text
  }

  // Lists
  ul, ol {
    margin-bottom: 1.25rem;
    padding-left: 1.25rem;
  }

  // Images
  img {
    max-width: 100%;
    height: auto;
    margin: 2rem 0;
  }

  // Meta information
  .post-meta {
    color: #888;
    font-size: 0.875rem;
    font-weight: 400;
    margin-top: 1rem;
  }

  // Header section
  header {
    margin-bottom: 3rem;
    text-align: center;

    h1 {
      margin-top: 0;
    }
  }

  // Attention grabber (new)
  .attention-grabber {
    font-size: 1.125rem;
    line-height: 1.6;
  }

  // Blog images
  .blog-image {
    margin: 2rem 0;
    
    img {
      width: 100%;
      height: auto;
      border-radius: 14px;
    }
    
    figcaption {
      text-align: center;
      font-size: 0.875rem;
      color: #666;
      margin-top: 0.5rem;
      font-style: italic;
    }
  }
}

// Responsive adjustments following Gutenberg's desktop sizes
@media (min-width: 768px) {
  .gutenberg-typography {
    font-size: 1.125rem; // 18px base for desktop
    line-height: $line-height-desktop;
  }
} 