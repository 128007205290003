/* Add Google Fonts import */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

/* New font class for Bebas Neue */
.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Normal weight (400) */
@font-face {
  font-family: 'GeistVF';
  src: url('./fonts/GeistVF.woff2') format('woff2'),
       url('./fonts/GeistVF.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

/* Medium weight (500) */
@font-face {
  font-family: 'GeistVF';
  src: url('./fonts/GeistVF.woff2') format('woff2'),
       url('./fonts/GeistVF.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/* Semibold weight (600) */
@font-face {
  font-family: 'GeistVF';
  src: url('./fonts/GeistVF.woff2') format('woff2'),
       url('./fonts/GeistVF.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

/* Bold weight (700) */
@font-face {
  font-family: 'GeistVF';
  src: url('./fonts/GeistVF.woff2') format('woff2'),
       url('./fonts/GeistVF.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

.custom-font {
  font-family: 'GeistVF', sans-serif;
  font-feature-settings: 'ss01' 1; /* Enables Stylistic Set 1 */
}

.custom-font-set2 {
  font-family: 'GeistVF', sans-serif;
  font-feature-settings: 'ss02' 1; /* Enables Stylistic Set 2 */
}
.line-1-bg {
 background-color: #FFCA08;
}
.line-2-bg {
  background-color: #00A551;
}
.line-4-bg {
  background-color: #B30276;
}
.line-1-text {
 color: rgb(34, 7, 55);
}
.line-2-text {
  color: #002613;
}
.line-4-text {
  color: #F5DAEC;
}
/* Add this CSS to your styles */
.truncate-2-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis for overflow */
}
@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

.animate-ping {
  animation: ping 1.75s cubic-bezier(0, 0, 0.2, 1) infinite;
}

#container {
  touch-action: none;
  overscroll-behavior: none;
  overflow: hidden;
}
.logoCN {
  width: 30px;
  height: auto;
}
.mytoronto {
  width: 140px;
  height: auto;
}
.logo {
  width: 172px;
  height: auto;
}
.bg-whitesmoke {
background-color: whitesmoke;
}
html {
background-color: whitesmoke !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.user-location-marker {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(0, 120, 255, 0.3);
  border: 2px solid #0078ff;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse 2s infinite;
}

.user-location-marker img {
  width: 20px;
  height: 20px;
}
.mapScale {
  scale: 1.3;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 120, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 120, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 120, 255, 0);
  }
}

.App-header {
  background-color: whitesmoke;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*  justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.mapboxgl-popup-close-button {
  width: 23px;
  height: 23px;
  font-size: 18px;
}

.mapboxgl-popup-content {
  position: relative;
  background: #fff;
  border-radius: 8px !important;
  box-shadow: 0 1px 2px rgba(0,0,0,.1);
  padding: 16px !important;
  pointer-events: auto;
}

.custom-chip {
  border-radius: 24px;
  padding: 2px 8px;
  font-size: 14px;
  font-weight: 600;
  width: fit-content;
}

.map-container {
  width: 100%;
}

.location-button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 10px;
  transition: transform 0.3s ease;
}

.location-button:active {
  transform: scale(0.95);
}

.location-button svg {
  transition: color 0.3s ease;
}

/* Add animation for ParkingSpots */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.parking-spot-card {
  animation: fadeInUp 0.5s ease-in-out;
}
.custom-menu-item {
  background-color: rgb(255, 255, 255);
}
.custom-menu-item:hover {
  background-color: hsl(240 4.88% 83.92% / 0.4);
}
.fixed-size {
  width: 3200px; /* Set fixed width */
  height: auto; /* Maintain aspect ratio */
  max-width: none; /* Prevent scaling down */
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #3f3f46;
  background: conic-gradient(#fff 0, transparent 0);
}

/* 1-hour representation: 1/24 of the circle */
.one-hour {
  background: conic-gradient(#3f3f46 0 45deg, transparent 45deg);
}

/* 2-hour representation: 2/24 of the circle */
.two-hours {
  background: conic-gradient(#3f3f46 0 65deg, transparent 65deg);
}

/* 4-hour representation: 4/24 of the circle */
.four-hours {
  background: conic-gradient(#3f3f46 0 115deg, transparent 115deg);
}

@supports (height: 100dvh) {
  .full-viewport-height {
    height: 100dvh;
  }
}

.mapboxgl-popup-content {
  padding: 8px !important;
}